import React from 'react'




class ExperimentLab extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }


    render() {


        return <div id="drawers">
            <div className="drawer">

            </div>
            <div className="drawer">

            </div>
        </div>
    }

}

export default ExperimentLab